.RepositoryViewer {
    display: grid;

    grid-template-areas:
        "a b"
        "a c"
        "d e"
        "d f";

    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto auto;

    width: 100%;
    height: 100%;
}

.Tweets {
    grid-area: a;
}

.VisualizationPicker {
    grid-area: b;
}

.Visualization {
    grid-area: c;
}

.Filters {
    grid-area: d;
}

.FilterPicker {
    grid-area: e;
}

.AddFilter {
    grid-area: f;
}




