.Select {
    border-radius: 25px;
    border-width: 0;

    min-height: 28px;
    padding: 2px 10px;
    margin: 2px;

    color: var(--fg-field-off);
    background-color: var(--bg-field-off);

    font-family: var(--font-regular);

    /* Non ho idea di perchè serva */
    vertical-align: bottom;
}

.Select:focus {
    outline: 0;

    color: var(--fg-field-on);
    background-color: var(--bg-field-on);
}