@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Poppins&display=swap');

* {
    box-sizing: border-box;
}

body {
    --font-title: "Bree Serif", serif;
    --font-regular: "Poppins", sans-serif;

    --bg-field-on: #FFFFFF;
    --bg-field-off: #C4C4C4;

    --fg-field-on: #777777;
    --fg-field-off: #626262;

    margin: 0;

    min-height: 100%;
    min-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-title);
}

*[disabled], .Disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

a {
    color: var(--fg-primary)
}

.ThemeDark {
    --bg-primary: #3B7097;
    --fg-primary: #FFFFFF;

    --bg-light: #34607A;
    --bg-dark: #254D61;
    --bg-accent: #437EA0;

    --bg-button-on: #5D815C;
    --bg-button-off: #F6E2BC;
    --fg-button-on: #FFFFFF;
    --fg-button-off: #536841;

    --bg-green: #536841;
    --fg-green: #FFFFFF;

    --bg-grey: #555555;
    --fg-grey: #FFFFFF;

    --bg-yellow: #B28000;
    --fg-yellow: #FFFFFF;

    --bg-red: #863821;
    --fg-red: #FFFFFF;

    --outline: white;
}

.ThemeLight {
    --bg-primary: #CAE4F6;
    --fg-primary: #336582;

    --bg-light: #B2CEDF;
    --bg-dark: #92B5D5;
    --bg-accent: #D1DEE8;

    --bg-button-on: #536841;
    --bg-button-off: #FFFFFF;
    --fg-button-on: #FFFFFF;
    --fg-button-off: #536841;

    --bg-green: #A9D09E;
    --fg-green: #536841;

    --bg-grey: #cccccc;
    --fg-grey: #7a7a7a;

    --bg-yellow: #F6E2BC;
    --fg-yellow: #B28100;

    --bg-red: #FF8866;
    --fg-red: #863821;

    --outline: black;
}

.Clickable {
    cursor: pointer;
}

.Clickable:hover {
    filter: brightness(110%);
}

.Clickable:active {
    filter: brightness(125%);
}