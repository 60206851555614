.RepositoryEditor {
    display: grid;

    grid-template-areas:
        "b c d"
        "b e e"
        "b f f"
        "b g g";
    grid-template-columns: 400px 1fr 1fr;
    grid-template-rows: auto auto 1fr auto;

    grid-gap: 10px;

    width: 100%;
    height: 100%;
}

.SearchByZone {
    grid-area: b;
}

.SearchByHashtags {
    grid-area: c;
}

.SearchByUser {
    grid-area: d;
}

.SearchByTimePeriod {
    grid-area: e;
}

.Conditions {
    grid-area: f;
}

.CreateDialog {
    grid-area: g;
}
