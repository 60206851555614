.InputWithIcon {
    display: flex;
    flex-direction: row;

    color: var(--fg-field-off);
    margin: 2px;

    width: 100%;
}

.InputWithIcon.Focused {
    color: var(--fg-field-on);
}

.IconPart {
    border-radius: 25px 0 0 25px;
    padding: 2px 4px;

    background-color: var(--bg-field-off);
}

.Focused .IconPart {
    background-color: var(--bg-field-on);
}

.InputPart {
    border-radius: 0 25px 25px 0;
    padding: 2px;
    flex-grow: 1;

    background-color: var(--bg-field-off);
    border-width: 0;

    outline: 0;

    /* Repeat properties overridden by <input> */
    font-family: var(--font-regular);
    color: var(--fg-field-off);

    width: 100%;
}


.Focused .InputPart {
    background-color: var(--bg-field-on);
    color: var(--fg-field-on);
}