.MissingLocalization {
    background-color: darkorange;
    color: black;
    cursor: help;
}

.MissingString {
    background-color: red;
    color: black;
    font-weight: bold;
    cursor: help;
}