@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&family=Poppins&display=swap);
* {
    box-sizing: border-box;
}

body {
    --font-title: "Bree Serif", serif;
    --font-regular: "Poppins", sans-serif;

    --bg-field-on: #FFFFFF;
    --bg-field-off: #C4C4C4;

    --fg-field-on: #777777;
    --fg-field-off: #626262;

    margin: 0;

    min-height: 100%;
    min-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-title);
}

*[disabled], .Disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

a {
    color: var(--fg-primary)
}

.ThemeDark {
    --bg-primary: #3B7097;
    --fg-primary: #FFFFFF;

    --bg-light: #34607A;
    --bg-dark: #254D61;
    --bg-accent: #437EA0;

    --bg-button-on: #5D815C;
    --bg-button-off: #F6E2BC;
    --fg-button-on: #FFFFFF;
    --fg-button-off: #536841;

    --bg-green: #536841;
    --fg-green: #FFFFFF;

    --bg-grey: #555555;
    --fg-grey: #FFFFFF;

    --bg-yellow: #B28000;
    --fg-yellow: #FFFFFF;

    --bg-red: #863821;
    --fg-red: #FFFFFF;

    --outline: white;
}

.ThemeLight {
    --bg-primary: #CAE4F6;
    --fg-primary: #336582;

    --bg-light: #B2CEDF;
    --bg-dark: #92B5D5;
    --bg-accent: #D1DEE8;

    --bg-button-on: #536841;
    --bg-button-off: #FFFFFF;
    --fg-button-on: #FFFFFF;
    --fg-button-off: #536841;

    --bg-green: #A9D09E;
    --fg-green: #536841;

    --bg-grey: #cccccc;
    --fg-grey: #7a7a7a;

    --bg-yellow: #F6E2BC;
    --fg-yellow: #B28100;

    --bg-red: #FF8866;
    --fg-red: #863821;

    --outline: black;
}

.Clickable {
    cursor: pointer;
}

.Clickable:hover {
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}

.Clickable:active {
    -webkit-filter: brightness(125%);
            filter: brightness(125%);
}
.BoxFull_BoxWithHeader__2dgU7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.BoxFull_BoxHeader__1Z1z6 {
    padding: 10px 40px;
    border-radius: 25px 25px 0 0;

    background-color: var(--bg-light);

    font-size: x-large;
    font-family: var(--font-title);

    white-space: nowrap;
    overflow-x: hidden;
}

.BoxFull_BoxBody__1xojf {
    min-height: 40px;
    padding: 10px 40px;
    border-radius: 0 0 25px 25px;

    background-color: var(--bg-dark);

    font-size: medium;
    font-family: var(--font-regular);

    -webkit-flex-grow: 1;

            flex-grow: 1;
}
.FormLabelled_LabelledForm__w4Edc {
    display: grid;

    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;

    grid-gap: 10px;

    -webkit-justify-content: center;

            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.FormLabel_LabelText__2IA9F {
    grid-column: 1;
    text-align: right;
}

.FormLabel_LabelContent__10kF1 {
    grid-column: 2;
}

.FormLabel_LabelContent__10kF1 > * {
    height: 100%;
    width: 100%;
}
.InputWithIcon_InputWithIcon__3t9PJ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;

    color: var(--fg-field-off);
    margin: 2px;

    width: 100%;
}

.InputWithIcon_InputWithIcon__3t9PJ.InputWithIcon_Focused__3Aype {
    color: var(--fg-field-on);
}

.InputWithIcon_IconPart__2FP_T {
    border-radius: 25px 0 0 25px;
    padding: 2px 4px;

    background-color: var(--bg-field-off);
}

.InputWithIcon_Focused__3Aype .InputWithIcon_IconPart__2FP_T {
    background-color: var(--bg-field-on);
}

.InputWithIcon_InputPart__1Nwu5 {
    border-radius: 0 25px 25px 0;
    padding: 2px;
    -webkit-flex-grow: 1;
            flex-grow: 1;

    background-color: var(--bg-field-off);
    border-width: 0;

    outline: 0;

    /* Repeat properties overridden by <input> */
    font-family: var(--font-regular);
    color: var(--fg-field-off);

    width: 100%;
}


.InputWithIcon_Focused__3Aype .InputWithIcon_InputPart__1Nwu5 {
    background-color: var(--bg-field-on);
    color: var(--fg-field-on);
}
.FormButton_FormButton__11CJf {
    grid-column: 1 / 3;
}

.Button_Button___EoQm {
    font-family: var(--font-regular);

    border-width: 0;
    border-radius: 25px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    padding: 5px;
    margin: 0 2px;

    cursor: pointer;
}

.Button_Button___EoQm:focus-visible {
    outline: 4px solid var(--outline);
}

.Button_ButtonGreen__e8Sn1 {
    color: var(--fg-green);
    background-color: var(--bg-green);
    border-color: var(--bg-green);
}

.Button_ButtonGrey__3LeJQ {
    color: var(--fg-grey);
    background-color: var(--bg-grey);
    border-color: var(--bg-grey);
}

.Button_ButtonYellow__27Qus {
    color: var(--fg-yellow);
    background-color: var(--bg-yellow);
    border-color: var(--bg-yellow);
}

.Button_ButtonRed__1AHUW {
    color: var(--fg-red);
    background-color: var(--bg-red);
    border-color: var(--bg-red);
}

.Button_Icon__3OJOv {
    font-size: large;
    line-height: 0;
    vertical-align: sub;
}
.FormAlert_FormAlert__2JuRU {
    grid-column: 1 / 3;
}

.Alert_BoxAlert__27JHl {
    padding: 5px 20px;
    border-radius: 25px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.Alert_BoxAlertRed__N3EfH {
    color: var(--fg-red);
    background-color: var(--bg-red);
}

.Alert_BoxAlertGreen__3tpu0 {
    color: var(--fg-green);
    background-color: var(--bg-green);
}

.Alert_BoxAlertYellow__j6zxA {
    color: var(--fg-yellow);
    background-color: var(--bg-yellow);
}

.Alert_BoxAlertGrey__2UVLo {
    color: var(--fg-grey);
    background-color: var(--bg-grey);
}
.BoxHeader_BoxHeaderOnly__tqhqX {
    padding: 10px 40px;
    border-radius: 25px;

    background-color: var(--bg-light);

    font-size: x-large;
    font-family: var(--font-title);
}

.Select_Select__27EPy {
    border-radius: 25px;
    border-width: 0;

    min-height: 28px;
    padding: 2px 10px;
    margin: 2px;

    color: var(--fg-field-off);
    background-color: var(--bg-field-off);

    font-family: var(--font-regular);

    /* Non ho idea di perchè serva */
    vertical-align: bottom;
}

.Select_Select__27EPy:focus {
    outline: 0;

    color: var(--fg-field-on);
    background-color: var(--bg-field-on);
}
.PageWithHeader_PageWithHeader__205vd {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-areas:
            "header button"
            "body body";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
}

.PageWithHeader_Header__1pIdn {
    grid-area: header;
    margin-bottom: 10px;
}

.PageWithHeader_Buttons__1Uy_x {
    grid-area: button;
    margin-left: 10px;
    margin-bottom: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.PageWithHeader_Buttons__1Uy_x > * {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.PageWithHeader_Body__1BKBj {
    grid-area: body;
}

.PageWithHeader_Body__1BKBj > * {
    width: 100%;
    height: 100%;
}
.BodyFlex_BodyFlex__3h1r8 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch;
    grid-gap: 10px;
    gap: 10px;
}

.BoxFullScrollable_BoxScrollableBody__1UVRw {
    position: relative;

    border-bottom-right-radius: 0;
}


.BoxFullScrollable_ScrollContainer__1caSP {
    position: absolute;

    padding: 10px 40px;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-y: scroll;

    /* Custom scrollbar (firefox only!) */
    scrollbar-color: var(--bg-light) var(--bg-dark);
    scrollbar-width: auto;
}

.SummaryBase_SummaryBase__3iv1z {
    width: 100%;
    height: 60px;

    margin: 10px 0;

    display: -webkit-flex;

    display: flex;
}

.SummaryLeft_SummaryLeft__FYpaT {
    width: 260px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;

    display: grid;
    grid-template-areas:
        "a b"
        "a c"
        "a d"
        "a e";
    grid-template-columns: auto 1fr;
    grid-template-rows: 5px 1fr 1fr 5px;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;

    background-color: var(--bg-accent);
    border-radius: 30px 0 0 30px;
}

.SummaryLeft_IconContainer__3YiRE {
    margin: 5px 15px 5px 5px;
    width: 50px;
    height: 50px;
    border-radius: 50px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    background-color: var(--bg-light);
    color: var(--fg-primary);

    font-size: x-large;

    grid-area: a;
}

.SummaryLeft_Title__1SH3T {
    grid-area: c;
    align-self: flex-end;
}

.SummaryLeft_Subtitle__2_NLd {
    grid-area: d;
    font-size: small;
    align-self: flex-start;
}

.SummaryLabels_SummaryLabels__19Hn1 {
    -webkit-flex-grow: 3;
            flex-grow: 3;

    padding: 5px 20px;

    background-color: var(--bg-light);

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 10px;
    -webkit-align-items: center;
            align-items: center;

    font-size: small;
}

.SummaryLabels_Label__3kSUe {
    grid-column: 1;
    text-align: right;
    font-weight: bold;
}

.SummaryLabels_Value__3iYaA {
    grid-column: 2;
}

.SummaryLabels_Upper__3eOzr {
    grid-row: 1;
}

.SummaryLabels_Lower__XnPw4 {
    grid-row: 2;
}

.SummaryButton_SummaryButton__2X3QT {
    width: 100px;

    box-shadow: none;
    border-radius: 0;
    margin: 0;
}


.SummaryRight_SummaryRight__2NoN0 {
    min-width: 30px;
    padding: 5px;

    background-color: var(--bg-accent);
    border-radius: 0 30px 30px 0;
}

.Empty_Empty__dYswh {
    opacity: 0.5;
}

.ButtonHeader_ButtonHeader__iH66D {
    box-shadow: none;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.BodyHorizontalHalves_BodyHorizontalHalves__1ouTp {
    display: grid;
    grid-template-areas: "upper" "lower";
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
}

.BodyHorizontalHalves_Upper__2AzeP {
    grid-area: upper;
}

.BodyHorizontalHalves_Upper__2AzeP > * {
    width: 100%;
    height: 100%;
}

.BodyHorizontalHalves_Lower__1GMju {
    grid-area: lower;
}

.BodyHorizontalHalves_Lower__1GMju > * {
    width: 100%;
    height: 100%;
}
.BoxLoggedIn_BoxLoggedInContents__aYh9z {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.RepositoryEditor_RepositoryEditor__1i066 {
    display: grid;

    grid-template-areas:
        "b c d"
        "b e e"
        "b f f"
        "b g g";
    grid-template-columns: 400px 1fr 1fr;
    grid-template-rows: auto auto 1fr auto;

    grid-gap: 10px;

    width: 100%;
    height: 100%;
}

.RepositoryEditor_SearchByZone__1O4m6 {
    grid-area: b;
}

.RepositoryEditor_SearchByHashtags__3VEZU {
    grid-area: c;
}

.RepositoryEditor_SearchByUser__2tpR_ {
    grid-area: d;
}

.RepositoryEditor_SearchByTimePeriod__2JI2w {
    grid-area: e;
}

.RepositoryEditor_Conditions__2-HZm {
    grid-area: f;
}

.RepositoryEditor_CreateDialog__15ML1 {
    grid-area: g;
}

.ButtonIconOnly_ButtonIconOnly__N_5M- {
    width: 40px;
    height: 40px;
    border-radius: 20px;

    -webkit-flex-shrink: 0;

            flex-shrink: 0;
}

.BoxMap_BoxMapContents__3S5zr {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0;
}

.BoxMap_MapContainer__1cscG {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    height: 300px;
    border-radius: 0 0 25px 25px;
}

.FormInline_FormInline__t1ZbT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;

    grid-gap: 10px;

    gap: 10px;

    -webkit-justify-content: space-between;

            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.FormInlineText_Input__2ysSm {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
}

.FormInlineText_Button__3E47E {

}
.ButtonToggleBeforeAfter_ButtonToggleBeforeAfter__2A-Hw {
    width: 75px;
    height: 28px;
}

.Badge_Badge__fGfox {
    display: -webkit-inline-flex;
    display: inline-flex;

    grid-gap: 5px;

    gap: 5px;
    padding: 0 5px;
    border-radius: 25px;
    margin: 0 2px;
}

.Badge_BadgeRed__w_QZk {
    background-color: var(--bg-red);
    color: var(--fg-red)
}

.Badge_BadgeYellow__2zuGq {
    background-color: var(--bg-yellow);
    color: var(--fg-yellow)
}

.Badge_BadgeGrey__-Zg4o {
    background-color: var(--bg-grey);
    color: var(--fg-grey)
}

.Badge_BadgeGreen__2iRGo {
    background-color: var(--bg-green);
    color: var(--fg-green)
}

.Badge_Text__3euM3 {
    max-width: 350px;
    overflow-x: hidden;
}

.Badge_Icon__24-Wo {
    width: 15px;
    text-align: center;
}

.ButtonSmallX_ButtonSmallX__1k6m2 {
    border-width: 0;
    border-radius: 9999px;
    line-height: 0;
    height: 18px;
    width: 18px;
    vertical-align: baseline;
    background-color: var(--bg-field-off);
    color: var(--fg-field-off);
    cursor: pointer;
    position: relative;
    top: -1px;
}

.ButtonSmallX_ButtonSmallX__1k6m2:hover {
    background-color: var(--bg-field-on);
    color: var(--fg-field-on);
}

.ButtonSmallX_ButtonSmallX__1k6m2:focus {
    outline: none;
}

.ButtonSmallX_ButtonSmallX__1k6m2:focus-visible {
    outline: 2px dashed var(--outline);
}

.Radio_Radio__2oM3C {
    font-size: inherit;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
}

.BodyHorizontalUpperGrow_BodyHorizontalUpperGrow__3vyMm {
    display: grid;
    grid-template-areas: "upper" "lower" "error";
    grid-template-rows: 1fr auto auto;
}

.BodyHorizontalUpperGrow_Upper__CPvNW {
    grid-area: upper;
}

.BodyHorizontalUpperGrow_Upper__CPvNW > * {
    width: 100%;
    height: 100%;
}

.BodyHorizontalUpperGrow_Lower__2lwrB {
    grid-area: lower;
    margin-top: 10px;
}

.BodyHorizontalUpperGrow_Lower__2lwrB > * {
    width: 100%;
    height: 100%;
}

.BodyHorizontalUpperGrow_Error__1QCNH {
    grid-area: error;
    margin-top: 10px;
}
.RepositoryViewer_RepositoryViewer__C-fx9 {
    display: grid;

    grid-template-areas:
        "a b"
        "a c"
        "d e"
        "d f";

    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto auto;

    width: 100%;
    height: 100%;
}

.RepositoryViewer_Tweets__2YWvs {
    grid-area: a;
}

.RepositoryViewer_VisualizationPicker__2pPbw {
    grid-area: b;
}

.RepositoryViewer_Visualization__11Iak {
    grid-area: c;
}

.RepositoryViewer_Filters__VbPBP {
    grid-area: d;
}

.RepositoryViewer_FilterPicker__1Mn72 {
    grid-area: e;
}

.RepositoryViewer_AddFilter__3ohXY {
    grid-area: f;
}





.SummaryText_SummaryText__3uXyE {
    -webkit-flex-grow: 3;
            flex-grow: 3;

    padding: 0 0 0 20px;

    background-color: var(--bg-light);

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-items: center;
            align-items: center;

    font-size: small;
}

.SummaryText_Text__1qi2L {
    height: 60px;
    overflow-y: auto;

    /* Custom scrollbar (firefox only!) */
    scrollbar-color: var(--bg-accent) var(--bg-light);
    scrollbar-width: auto;
}
.BoxWordcloud_WordcloudContainer__2uQRU {
    width: 100%;
    height: 100%;
}

.FormInlineLocation_Radius__2WJz7, .FormInlineLocation_Latitude__1qCFK, .FormInlineLocation_Longitude__1oNiG {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
}

.FormInlineLocation_Button__3w5yJ {

}
.GlobalLanguage_MissingLocalization__3b2Ut {
    background-color: darkorange;
    color: black;
    cursor: help;
}

.GlobalLanguage_MissingString__2O-1A {
    background-color: red;
    color: black;
    font-weight: bold;
    cursor: help;
}
.Sidebar_Sidebar__1xnfL {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 25px;
    gap: 25px;

    width: 250px;
    height: 100%;

    background-color: var(--bg-light);
    border-radius: 25px;

    padding: 10px;
}

.Logo_Logo__xUeW9 {

}

.ButtonSidebar_ButtonLink__1gmAQ {
    text-decoration: none;
}

.ButtonSidebar_ButtonSidebar__1SG_K {
    font-family: var(--font-title);

    border-width: 0;
    border-radius: 0 25px 25px 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    padding: 10px;
    margin: 0;

    cursor: pointer;

    background-color: var(--bg-button-off);
    color: var(--fg-button-off);

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 20px;
    gap: 20px;

    font-size: large;

    /* Hackerino per ignorare il padding */
    width: calc(100% + 10px);
    position: relative;
    left: -10px;
}

.ButtonSidebar_ButtonSidebar__1SG_K.ButtonSidebar_Active___Ui7Z {
    background-color: var(--bg-button-on);
    color: var(--fg-button-on);
}

.ButtonSidebar_ButtonLink__1gmAQ:focus-visible .ButtonSidebar_ButtonSidebar__1SG_K {
    outline: 4px solid var(--outline);
}

.ButtonSidebar_ButtonIcon__Bp69W {
    font-size: x-large;

    line-height: 0;

    /* Hackerino per centrare le icone */
    position: relative;
    top: 2px;
}

.ButtonSidebar_ButtonText__jBsJS {

}

.WebsiteWithSidebar_WebsiteWithSidebar__20wn1 {
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-areas:
        "a b";
    grid-template-columns: auto 1fr;

    grid-gap: 10px;
}

.WebsiteWithSidebar_Sidebar__37jnH {
    grid-area: a;
}

.WebsiteWithSidebar_Main__3vApR {
    grid-area: b;
}

.Window_Window__K_eN3 {
    width: 100vw;
    height: 100vh;

    color: var(--fg-primary);
    background-color: var(--bg-primary);

    padding: 10px;
}

