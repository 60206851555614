.BoxMapContents {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.MapContainer {
    flex-grow: 1;
    height: 300px;
    border-radius: 0 0 25px 25px;
}
