.ButtonLink {
    text-decoration: none;
}

.ButtonSidebar {
    font-family: var(--font-title);

    border-width: 0;
    border-radius: 0 25px 25px 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    padding: 10px;
    margin: 0;

    cursor: pointer;

    background-color: var(--bg-button-off);
    color: var(--fg-button-off);

    display: flex;
    flex-direction: row;
    gap: 20px;

    font-size: large;

    /* Hackerino per ignorare il padding */
    width: calc(100% + 10px);
    position: relative;
    left: -10px;
}

.ButtonSidebar.Active {
    background-color: var(--bg-button-on);
    color: var(--fg-button-on);
}

.ButtonLink:focus-visible .ButtonSidebar {
    outline: 4px solid var(--outline);
}

.ButtonIcon {
    font-size: x-large;

    line-height: 0;

    /* Hackerino per centrare le icone */
    position: relative;
    top: 2px;
}

.ButtonText {

}
