.SummaryLeft {
    width: 260px;
    flex-shrink: 0;

    display: grid;
    grid-template-areas:
        "a b"
        "a c"
        "a d"
        "a e";
    grid-template-columns: auto 1fr;
    grid-template-rows: 5px 1fr 1fr 5px;
    justify-content: stretch;
    align-items: stretch;

    background-color: var(--bg-accent);
    border-radius: 30px 0 0 30px;
}

.IconContainer {
    margin: 5px 15px 5px 5px;
    width: 50px;
    height: 50px;
    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--bg-light);
    color: var(--fg-primary);

    font-size: x-large;

    grid-area: a;
}

.Title {
    grid-area: c;
    align-self: flex-end;
}

.Subtitle {
    grid-area: d;
    font-size: small;
    align-self: flex-start;
}
