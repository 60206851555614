.Sidebar {
    display: flex;
    flex-direction: column;
    gap: 25px;

    width: 250px;
    height: 100%;

    background-color: var(--bg-light);
    border-radius: 25px;

    padding: 10px;
}
