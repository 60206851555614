.WebsiteWithSidebar {
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-areas:
        "a b";
    grid-template-columns: auto 1fr;

    grid-gap: 10px;
}

.Sidebar {
    grid-area: a;
}

.Main {
    grid-area: b;
}
